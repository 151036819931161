<template>
  <div class="ma-0 pa-0">
    <v-container fluid v-if="!goodsSelected">
      <v-row align="center">
        <v-col cols="6">
          <v-text-field v-model="goodsSearchFilter" :label="tLabel('Code, description')" prepend-inner-icon="mdi-magnify" dense @input="(val) => (goodsSearchFilter = val ? val.toUpperCase() : null)"/>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-0 pt-0">
        <v-col cols="12">
          <v-simple-table height="186" fixed-header dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ tLabel("Code") }}</th>
                  <th class="text-left">{{ tLabel("Descr.") }}</th>
                  <th class="text-left">{{ tLabel("Colli") }}</th>
                  <th class="text-left">{{ tLabel("Weight") }}</th>
                  <th class="text-left">{{ tLabel("Position") }}</th>
                  <th class="text-left">{{ tLabel("Date In") }}</th>
                </tr>
              </thead>
              <tbody v-if="goodsFiltered !== null && goodsFiltered.length > 0">
                <tr v-for="goods in goodsFiltered" :key="goods.visitId" @click="onGoodsClick(goods)" class="pointer">
                  <td>{{ goods.code }}</td>
                  <td>{{ goods.description }}</td>
                  <td>{{ goods.quantity }}</td>
                  <td>{{ goods.weight }}</td>
                  <td>{{ goods.position && goods.position !== null ? goods.position : "N/A" }}</td>
                  <td>
                    <v-icon class="pr-1" v-if="goods.ata && goods.ata !== null">mdi-warehouse</v-icon>
                    <v-icon class="pr-1" v-else>mdi-clock-fast</v-icon>
                    {{ goods.ata && goods.ata !== null ? goods.ata : goods.eta | formatDateTimeFromDate }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6">{{ tLabel("Nessuna merce disponibile.") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid v-else style="height: 270px">
      <v-row align="center">
        <v-col cols="12" class="pa-0">
          <v-simple-table fixed-header dense class="grey lighten-5">
            <template v-slot:default>
              <thead class="grey lighten-5">
                <tr>
                  <th class="text-left">{{ tLabel("Code") }}</th>
                  <th class="text-left">{{ tLabel("Descr.") }}</th>
                  <th class="text-left">{{ tLabel("Weight") }}</th>
                  <th class="text-left">{{ tLabel("Date In") }}</th>
                </tr>
              </thead>
              <tbody v-if="gts && gts !== null">
                <tr>
                  <td class="text-subtitle-1 font-weight-medium primary--text">{{ gts.code }}</td>
                  <td>{{ gts.description }}</td>
                  <td>{{ gts.weight }}</td>
                  <td>
                    <v-icon class="pr-1" v-if="gts.ata && gts.ata !== null">mdi-warehouse</v-icon>
                    <v-icon class="pr-1" v-else>mdi-clock-fast</v-icon>
                    {{ gts.ata && gts.ata !== null ? gts.ata : gts.eta | formatDateTimeFromDate }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row align="center" class="grey lighten-3">
        <v-col cols="3" class="text-h6"> STUFFING </v-col>
        <v-col cols="3" class="text-h6"><v-icon x-large>mdi-chevron-right</v-icon></v-col>
        <v-col cols="6" class="text-right">
          <v-container fluid class="ma-0 pa-0">
            <v-row align="center">
              <v-col cols="6" class="text-right">
                <v-text-field background-color="white" :label="tLabel('Colli')" v-model.number="stripQuantity" :rules="[rules.numeric]" />
              </v-col>
              <v-col cols="1" class="text-center">/</v-col>
              <v-col cols="5" class="text-left">{{ gts.quantity }}</v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="text-right">
          <v-btn small outlined @click="discardGoodsSelection" class="mr-2"><v-icon>mdi-close</v-icon></v-btn>
          <v-btn small color="success" @click="confirmGoodsSelection"><v-icon>mdi-check</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row align="center" class="mt-0 pt-0">
        <v-col cols="12">
          <v-card color="grey lighten-5" rounded="0">
            <v-card-text>
              <v-row align="center" class="mt-0 pt-0">
                <v-col cols="12">
                  <span class="text-h6">{{ tLabel("Merce selezionata") }}:</span>
                </v-col>
              </v-row>

              <v-row align="center" class="mt-0 pt-0">
                <v-col cols="12">
                  <v-simple-table fixed-header dense class="grey lighten-5">
                    <template v-slot:default>
                      <thead class="grey lighten-5">
                        <tr>
                          <th></th>
                          <th class="text-left">{{ tLabel("Code") }}</th>
                          <th class="text-left">{{ tLabel("Descr.") }}</th>
                          <th class="text-left">{{ tLabel("Colli") }}</th>
                          <th class="text-left">{{ tLabel("Weight") }}</th>
                          <th class="text-left">{{ tLabel("Position") }}</th>
                          <th class="text-left">{{ tLabel("Date In") }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="goodsToAdd && goodsToAdd !== null && goodsToAdd.length > 0">
                        <tr v-for="gta in goodsToAdd" :key="gta.visitId">
                          <td>
                            <v-icon small @click="removeFromGoodsToAdd(gta)" :title="tLabel('Rimuovi merce selezionata.')">mdi-close</v-icon>
                          </td>
                          <td>{{ gta.code }}</td>
                          <td>{{ gta.description }}</td>
                          <td>{{ gta.quantity }}</td>
                          <td>{{ gta.weight }}</td>
                          <td>{{ gta.position && gta.position !== null ? gta.position : "N/A" }}</td>
                          <td>
                            <v-icon class="pr-1" v-if="gta.ata && gta.ata !== null">mdi-warehouse</v-icon>
                            <v-icon class="pr-1" v-else>mdi-clock-fast</v-icon>
                            {{ gta.ata && gta.ata !== null ? gta.ata : gta.eta | formatDateTimeFromDate }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="8">{{ tLabel("Nessuna merce selezionata.") }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "GoodsSelectionPerOperationPanel",
  data() {
    return {
      goodsSearchFilter: null,
      goodsSelected: false,
      gts: null,
      stripQuantity: 0,
    };
  },
  props: {
    goods: {
      type: Array,
      required: true,
    },
    goodsToAdd: {
      type: Array,
      required: true,
    },
  },
  mixins: [FormatsMixins],
  async mounted() {},
  computed: {
    goodsFiltered() {
      var gaf = JSON.parse(JSON.stringify(this.goods));
      if (this.goodsToAdd !== null && this.goodsToAdd.length > 0) {
        var goodsVisitIds = [];
        this.goodsToAdd.forEach((goo) => {
          goodsVisitIds.push(goo.visitId);
        });
        gaf = gaf.filter((goo) => !goodsVisitIds.includes(goo.visitId));
      }
      if (this.goodsSearchFilter !== null && this.goodsSearchFilter !== "") {
        return gaf.filter(
          (goo) => (goo.code !== null && goo.code.includes(this.goodsSearchFilter)) || (goo.description !== null && goo.description.includes(this.goodsSearchFilter))
        );
      }
      return gaf;
    },
  },
  methods: {
    onGoodsClick(goods) {
      this.gts = JSON.parse(JSON.stringify(goods));
      this.stripQuantity = this.gts.quantity;
      if (!this.gts.ata || this.gts.ata === null) {
        this.confirmGoodsSelection();
      } else {
        this.goodsSelected = true;
      }
    },
    confirmGoodsSelection() {
      if (this.goodsToAdd === null) {
        this.goodsToAdd = new Array();
      }
      let gtsStripped = JSON.parse(JSON.stringify(this.gts));
      gtsStripped.weight = (Math.round((gtsStripped.weight / gtsStripped.quantity) * this.stripQuantity)).toFixed(2);
      gtsStripped.quantity = this.stripQuantity;    
      this.goodsToAdd.push(gtsStripped);
      this.gts = null;
      this.goodsSelected = false;
    },
    discardGoodsSelection() {
      this.gts = null;
      this.goodsSelected = false;
    },
    removeFromGoodsToAdd(goods) {
      var index = this.goodsToAdd.indexOf(goods);
      if (index > -1) {
        this.goodsToAdd.splice(index, 1);
      }
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
