<template>
  <v-container class="ma-0 pa-0">
    <v-row align="end">
      <v-col cols="12" class="ma-1 pa-0">
        <span class="text-caption">{{label}}</span>
      </v-col>      
    </v-row>   
    <v-row align="end" :style="heightStyle">
      <v-col cols="12" class="ma-1 pa-0">
        <slot name="content" :data="datetimeValue?datetimeValue:(numericValue?numericValue:value)">
          <span>
            <v-icon v-if="icon" large color="primary">{{icon}}</v-icon>
            <span :class="'text-h6' + (icon?' mx-2 px-2':'')" v-if="datetimeValue">{{datetimeValue | formatDateTime}}</span>
            <span :class="'text-h6' + (icon?' mx-2 px-2':'')" v-else-if="numericValue">{{numericValue}}</span>
            <span :class="'text-h6' + (icon?' mx-2 px-2':'')" v-else>{{value}}</span>
          </span>
        </slot>
      </v-col>
    </v-row>
    <v-row>
      <v-divider class="gray ma-1 pa-0"></v-divider>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment"; 
export default {
  name: "LabeledContent",
  props: {
    value: {
      type: String,
    },
    numericValue: {
      type: Number,
    },
    datetimeValue: {
      type: String,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    height: {
      type: String,
      default: '40'
    }
  },
  data() {
    return {

    };
  },
  computed: {
    heightStyle() {
      return "height: " + this.height + "px";
    }
  },
  filters: {
    formatDate(date) {
        if (!date) return 'N/A';
        return moment(date).format('DD/MM/YYYY');
    },
    formatDateTime(date) {
        if (!date) return 'N/A';
        return moment(date).format('DD/MM/YYYY HH:mm');
    },
  }
};
</script>

<style scoped>
.label {
   color: #0D47A1;
}
</style>